





















import { IMenuItem } from "@/types";
import { AppModule } from "@/store/modules/app";
import { UserModule } from "@/store/modules/user";
import { computed, defineComponent, onMounted, ref } from "@vue/composition-api";

export default defineComponent({
  setup(props, { root }) {
    const isOpened = ref(true);
    const sidebarMenu = computed(() => UserModule.sidebarMenu);
    const drawerTitle = computed(() => AppModule.drawerTitle);

    onMounted(() => {
      root.$store.subscribe((mutation, state) => {
        switch (mutation.type) {
          case "TOGGLE_SIDEBAR":
            isOpened.value = AppModule.sidebar.opened;
            break;
        }
      });
    });

    function isHighlighted(item: IMenuItem): string {
      const currentPageName = root.$route.path == "/" ? "home" : root.$route.name;
      return item.name == currentPageName ? "secondary--text" : "";
    }

    return { isOpened, sidebarMenu, drawerTitle, isHighlighted };
  }
});

